import React from "react";
import { BtnOrange, CustomiseCard, Heading } from "../style";
import { useTranslation } from "react-i18next";
import {
  CustomUL,
  JobDescription,
  JobDescriptionPoint,
  JobDescriptionTitle,
  JobInfoWrapper,
  JobPost,
  JobPostWrap,
  JobSocialButton,
  JobSocialMedia,
  JobTitle,
} from "../JobsList/style";

const JobPostDetail = () => {
  const { t } = useTranslation();
  return (
    <>
      <CustomiseCard padding="36px">
        <JobInfoWrapper>
          <JobTitle>
            <Heading>{t("careerDetails.jobPostDetails.postDetail.title")}</Heading>
          </JobTitle>
          <JobSocialMedia>
            <JobSocialButton sx={{ mr: "10px" }}>
              <img src="/images/TypoLiner.svg" alt="not-found" style={{ marginRight: "10px" }} />
              {t("careerDetails.jobPostDetails.postDetail.socialBtn1")}
            </JobSocialButton>
            <JobSocialButton sx={{ mr: "10px" }}>
              <img src="/images/ArchiveLiner.svg" alt="not-found" style={{ marginRight: "10px" }} />
              {t("careerDetails.jobPostDetails.postDetail.socialBtn2")}
            </JobSocialButton>
            <BtnOrange>{t("careerDetails.jobPostDetails.postDetail.socialBtn3")}</BtnOrange>
          </JobSocialMedia>
        </JobInfoWrapper>
        <JobPost margin="24px 0">
          <JobPostWrap>
            <img src="/images/LocationLiner.svg" alt="not-found" />
            <span>{t("careerDetails.jobPostDetails.postDetail.location")}</span>
          </JobPostWrap>
          <JobPostWrap>
            <img src="/images/TimeLiner.svg" alt="not-found" />
            <span>{t("careerDetails.jobPostDetails.postDetail.date")}</span>
          </JobPostWrap>
          <JobPostWrap>
            <img src="/images/ContentLiner.svg" alt="not-found" />
            <span>{t("careerDetails.jobPostDetails.postDetail.post")}</span>
          </JobPostWrap>
        </JobPost>
        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.1.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>{t("careerDetails.jobPostDetails.postDetail.descriptions.1.points.desc")}</JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.2.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>{t("careerDetails.jobPostDetails.postDetail.descriptions.2.points.desc")}</JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.3.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>{t("careerDetails.jobPostDetails.postDetail.descriptions.3.points.desc")}</JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>
            <ol type="A">
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.point1")}</li>
              <CustomUL paddingInlineStart="8px">
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.1")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.2")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.3")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.4")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.5")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.6")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.7")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.8")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.9")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.10")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.11")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.12")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.13")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.14")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList.15")}</li>
              </CustomUL>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.point2")}</li>
              <CustomUL paddingInlineStart="8px">
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList2.1")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList2.2")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList2.3")}</li>
                <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.4.points.list.subList2.4")}</li>
              </CustomUL>
            </ol>
          </JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>
            <CustomUL paddingInlineStart="27px">
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.1")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.2")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.3")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.4")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.5")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.6")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.7")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.8")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.5.points.list.9")}</li>
            </CustomUL>
          </JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>
            <CustomUL paddingInlineStart="27px">
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.points.list.1")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.points.list.2")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.points.list.3")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.points.list.4")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.points.list.5")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.6.points.list.6")}</li>
            </CustomUL>
          </JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.7.title")}</JobDescriptionTitle>
          <JobDescriptionPoint>
            <CustomUL paddingInlineStart="27px">
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.7.points.list.1")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.7.points.list.2")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.7.points.list.3")}</li>
              <li>{t("careerDetails.jobPostDetails.postDetail.descriptions.7.points.list.4")}</li>
            </CustomUL>
          </JobDescriptionPoint>
        </JobDescription>

        <JobDescription>
          <JobDescriptionTitle>{t("careerDetails.jobPostDetails.postDetail.descriptions.8.title")}</JobDescriptionTitle>
        </JobDescription>

        <BtnOrange>{t("careerDetails.jobPostDetails.postDetail.socialBtn3")}</BtnOrange>
      </CustomiseCard>
    </>
  );
};

export default JobPostDetail;
