import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import DropDown from "../DropDown";
import LanguageSelector from "../Navbar/LanguageSelector";
import { LoginBtn, Logo } from "../Navbar/style";
import { FlexDiv, MobileNavBar } from "./style";
import { MenuBar, MenuItem } from "./style";
import { t } from "i18next";
import MobileDropDown from "../MobileDropDown";
function MobileNav({ pages, onClose, language, onSelect }) {
  return (
    <MobileNavBar className="mobile">
      <FlexDiv>
        <Link to={"/"} onClick={onClose}>
          <Logo className="logo" src="/images/Logo.svg" />
        </Link>
        <Button onClick={onClose}>
          <Logo className="close" src="/images/close.svg" />
        </Button>
      </FlexDiv>
      <MenuBar>
        {pages.map((page) =>
          page.options ? (
            <MobileDropDown page={page} onClose={onClose} />
          ) : (
            <MenuItem to={page.navigate} key={page.name} onClick={onClose}>
              {page.name}
            </MenuItem>
          )
        )}
        {/*<LanguageSelector defaultLanguage={language} oncClose={onclose} onSelect={onSelect} />*/}
      </MenuBar>
        <Link to={"https://platform.digidoe.com/"} className={"mailLink"} target={"_blank"}>
          <LoginBtn>{t("navbar.login")}</LoginBtn>
        </Link>
    </MobileNavBar>
  );
}
export default MobileNav;
