import React from "react";
import { InputDiv, Input } from "./style";

const SearchBar = ({ margin, width, placeholder }) => {
  return (
    <InputDiv margin={margin} width={width}>
      <Input type="text" name="" id="" placeholder={placeholder} />
      <img className="search" src="/images/search-zoom-in.svg" alt="not-found" />
    </InputDiv>
  );
};

export default SearchBar;
