import * as React from "react";
import { useState, useEffect } from "react";
import MobileNav from "../MobileNav";
import { LoginBtn, Logo, MenuBar, MenuItem, PrimaryNav, LangSelectorContainer, ActionsContainer } from "./style";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PopperDropdown from "../PoperDropdown";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

function Navbar() {
  const [isLargeScreen, setIsLargeScreen] = useState(Boolean);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("en");
  const { i18n, t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pages = [
    { name: t("navbar.services"), navigate: "/services" },
    { name: t("navbar.modules"), navigate: "/modules" },
    { name: t("navbar.aboutUs"), navigate: "/aboutUs" },
    // {
    //   name: t("navbar.useCase"),
    //   options: [
    //     {
    //       name: t("navbar.forEmis"),
    //       nameSecondary: t("navbar.forBusiness"),
    //       imageUrl: "/images/use-case-img1.svg",
    //       navigate: "/useCase/emis",
    //     },
    //     {
    //       name: t("navbar.forFamily"),
    //       nameSecondary: t("navbar.digitalBanking"),
    //       imageUrl: "/images/use-case-img2.svg",
    //       navigate: "/useCase/familyOffices",
    //     },
    //     {
    //       name: t("navbar.forBanks"),
    //       nameSecondary: t("navbar.overseasBusiness"),
    //       imageUrl: "/images/use-case-img3.svg",
    //       navigate: "/useCase/banks",
    //     },
    //   ],
    // },
    // {
    //   name: t("navbar.resources"),
    //   options: [
    //     /*{
    //       name: t("navbar.helpCenter"),
    //       imageUrl: "/images/help-center-nav.svg",
    //       imageSecondary: "/images/right-arrow.svg",
    //       navigate: "/support",
    //     },
    //     {
    //       name: t("navbar.news"),
    //       imageUrl: "/images/news-nav.svg",
    //       imageSecondary: "/images/right-arrow.svg",
    //       navigate: "/news",
    //     },*/
    //     {
    //       name: t("navbar.termsAndCondition"),
    //       imageUrl: "/images/terms.svg",
    //       imageSecondary: "/images/right-arrow.svg",
    //       navigate: "/termsAndConditions",
    //     },
    //     {
    //       name: t("navbar.privacyPolicy"),
    //       imageUrl: "/images/policy.svg",
    //       imageSecondary: "/images/right-arrow.svg",
    //       navigate: "/privacyPolicy",
    //     },
    //   ],
    // },
    {
      name: t("navbar.pricing"),
      navigate: "/pricing"
    },
    /*{
      name: t("navbar.aboutUs"),
      options: [
        {
          name: t("navbar.gettingStarted"),
          nameSecondary: t("navbar.gettingStartedContent"),
          navigate: "/aboutUs",
        },
        {
          name: t("navbar.foundation"),
          nameSecondary: t("navbar.foundationContent"),
          navigate: "#!",
        },
        {
          name: t("navbar.components"),
          nameSecondary: t("navbar.componentsContent"),
          navigate: "#!",
        },
        {
          name: t("navbar.plugins"),
          nameSecondary: t("navbar.pluginsContent"),
          navigate: "#!",
        },
      ],
    },*/
  ];

  const languageSelectHandler = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value).then(() => {
      document.body.dir = i18n.dir();
    });
  };

  const handleResize = () => {
    if (window.innerWidth < 980) {
      setIsLargeScreen(false);
    } else {
      setIsLargeScreen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);
  return (
    <>
      <PrimaryNav>
        <MenuItem to={"/"}>
          <Logo src="/images/Logo.svg" />
        </MenuItem>

        {!isLargeScreen ? (
          <>
            <MenuItem to="#!" onClick={handleOpen}>
              <Logo src="/images/nav-icon.svg" className="nav-icon" />
            </MenuItem>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box>
                <MobileNav onClose={handleClose} pages={pages} language={language} onSelect={languageSelectHandler} />
              </Box>
            </Modal>
          </>
        ) : (
          <>
            <MenuBar>
              {pages.map((page) =>
                page.options ? (
                  <PopperDropdown page={page} key={page.name}/>
                ) : (
                  <MenuItem to={page.navigate} key={page.name}>
                    {page.name}
                  </MenuItem>
                )
              )}
              {/* Todo: prevent widening of navbar while choosing other language in footer - wasn't able to reproduce */}

            </MenuBar>
            <ActionsContainer>
              <LangSelectorContainer>
                {<LanguageSelector defaultLanguage={language} onSelect={languageSelectHandler} language={language} />}
              </LangSelectorContainer>

              <Link to={"https://platform.digidoe.com/"} className={"mailLink"} target={"_blank"}>
                <LoginBtn>
                  {t("navbar.login")}
                </LoginBtn>
              </Link>
            </ActionsContainer>

          </>
        )}
      </PrimaryNav>
    </>
  );
};

export default Navbar;
