import * as React from "react";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, PaginationItem } from "@mui/material";
import { ParaTitle, CardPara, Date } from "../style";
import { CenterHeading, FlexDiv, HeadingSecondary, PaginationWrapper, NewsWrapper, NewsCard } from "./style";
import { useTranslation } from "react-i18next";
import { CustomPagination } from "../JobOpportunity/style";
import { FirstPageIcon } from "../../Icons/FirstPageIcon";
import { LastPageIcon } from "../../Icons/LastPageIcon";

export const NewsMain = () => {
  const { t } = useTranslation();
  return (
    <NewsWrapper>
      <CenterHeading>{t("news.news")}</CenterHeading>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076.svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.feb")}</Date>
                <ParaTitle>{t("news.title1")}</ParaTitle>
                <CardPara>{t("news.content1")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076 (1).svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.jan")}</Date>
                <ParaTitle>{t("news.title2")}</ParaTitle>
                <CardPara>{t("news.content2")}.</CardPara>
                <FlexDiv>
                  <img src="images/fintech.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.fintechEnergy")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Frame 48095708.svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.may")}</Date>
                <ParaTitle>{t("news.title3")}</ParaTitle>
                <CardPara>{t("news.content3")}</CardPara>
                <FlexDiv>
                  <img src="images/finextra.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.finextra")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Frame 48095708.svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.may")}</Date>
                <ParaTitle>{t("news.title3")}</ParaTitle>
                <CardPara>{t("news.content3")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076 (2).svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.feb")}</Date>
                <ParaTitle>{t("news.title1")}</ParaTitle>
                <CardPara>{t("news.content1")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076 (1).svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.jan")}</Date>
                <ParaTitle>{t("news.title2")}</ParaTitle>
                <CardPara>{t("news.content2")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076 (3).svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.feb")}</Date>
                <ParaTitle>{t("news.title1")}</ParaTitle>
                <CardPara>{t("news.content1")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076 (1).svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.jan")}</Date>
                <ParaTitle>{t("news.title2")}</ParaTitle>
                <CardPara>{t("news.content2")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <NewsCard>
            <CardActionArea>
              <CardMedia component="img" image="images/Rectangle 22076 (2).svg" alt="green iguana" />
              <CardContent sx={{ display: "grid", gap: "12px" }}>
                <Date>{t("news.may")}</Date>
                <ParaTitle>{t("news.title3")}</ParaTitle>
                <CardPara>{t("news.content3")}</CardPara>
                <FlexDiv>
                  <img src="images/business-cloud.svg" alt="business" />
                  <div>
                    <CardPara active="true">{t("news.publishedBy")}</CardPara>
                    <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
                  </div>
                </FlexDiv>
              </CardContent>
            </CardActionArea>
          </NewsCard>
        </Grid>
      </Grid>
      <PaginationWrapper>
        <CustomPagination
          count={10}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              components={{
                first: FirstPageIcon,
                last: LastPageIcon,
              }}
              {...item}
            />
          )}
        />
      </PaginationWrapper>
    </NewsWrapper>
  );
};
