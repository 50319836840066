import React from "react";
import { CustomiseCard } from "../style";
import {
  JobDescription,
  JobDescriptionPoint,
  JobDescriptionTitle,
  JobInfoWrapper,
  JobLink,
  JobPost,
  JobPostWrap,
  JobSocialButton,
  JobSocialMedia,
  JobTitle,
} from "./style";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const JobsList = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {props?.jobs.map((job, key) => (
        <CustomiseCard padding="24px" margin="0 0 16px 0" key={key}>
          <JobInfoWrapper>
            <JobTitle>{t(job.jobTitle)}</JobTitle>
            <JobSocialMedia>
              <JobSocialButton sx={{ mr: "10px" }}>
                <img src="/images/TypoLiner.svg" alt="not-found" style={{ marginRight: "10px" }} />
                {t("career.jobs.socialBtn1")}
              </JobSocialButton>
              <JobSocialButton>
                <img src="/images/ArchiveLiner.svg" alt="not-found" style={{ marginRight: "10px" }} />
                {t("career.jobs.socialBtn2")}
              </JobSocialButton>
            </JobSocialMedia>
          </JobInfoWrapper>
          <JobPost margin="16px 0 0 0">
            <JobPostWrap>
              <img src="/images/LocationLiner.svg" alt="not-found" />
              <span>{t(job.jobPost.location)}</span>
            </JobPostWrap>
            <JobPostWrap>
              <img src="/images/TimeLiner.svg" alt="not-found" />
              <span>{t(job.jobPost.date)}</span>
            </JobPostWrap>
            <JobPostWrap>
              <img src="/images/ContentLiner.svg" alt="not-found" />
              <span>{t(job.jobPost.position)}</span>
            </JobPostWrap>
          </JobPost>
          <Divider variant="middle" sx={{ margin: "16px 0", border: "1px solid #F1F3F5" }} />
          <JobDescription>
            <JobDescriptionTitle>{t(job.jobDescription.title)}</JobDescriptionTitle>
            <JobDescriptionPoint>{t(job.jobDescription.point1)}</JobDescriptionPoint>
            <JobDescriptionPoint>{t(job.jobDescription.point2)}</JobDescriptionPoint>
            <JobLink href="#" underline="none">
              {t("career.jobs.jobLinkText")}
              <img src="/images/RightArrowLiner.svg" alt="not-found" />
            </JobLink>
          </JobDescription>
        </CustomiseCard>
      ))}
    </>
  );
};

export default JobsList;
