import * as React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { CustomAccordion, TypographySummary } from "./style";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";

const FiltersAccordion = (props) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {props?.accordions.map((accordion, key) => (
        <>
          <CustomAccordion expanded={expanded === accordion.id} onChange={handleChange(accordion.id)} key={key}>
            <AccordionSummary
              expandIcon={
                expanded === accordion.id ? (
                  <img src="/images/MinimizeIcon.svg" alt="MinimizeIcon" />
                ) : (
                  <img src="/images/ExpandIcon.svg" alt="ExpandIcon" />
                )
              }
              aria-controls={key + "panel1bh-content"}
              id={key + "panel1bh-header-"}
            >
              <TypographySummary>
                {t(accordion.summary)}
                {key === 0 ? <span>2</span> : ""}
              </TypographySummary>
            </AccordionSummary>
            <AccordionDetails>{accordion.details}</AccordionDetails>
          </CustomAccordion>

          {accordion.isDivider ? (
            <Divider
              variant="middle"
              sx={{
                margin: "36px 0",
                border: "1px solid rgba(162, 162, 163, 0.2)",
              }}
            />
          ) : (
            ""
          )}
        </>
      ))}
    </div>
  );
};

export default FiltersAccordion;
