import React from "react";
import { CustomiseCard } from "../style";
import SearchBar from "../Searchbar";
import { Divider } from "@mui/material";
import { ClearFilterLink, FiltersTitle, TitleWrap } from "./style";
import FiltersAccordion from "../FiltersAccordion";
import CustomCheckbox from "../CustomCheckbox";
import { useTranslation } from "react-i18next";

const filters = [
  {
    id: 1,
    summary: "career.jobsFilters.filter1.title",
    details: (
      <>
        <CustomCheckbox
          isChecked={true}
          label="career.jobsFilters.filter1.summary.check1"
        />
        <CustomCheckbox
          isChecked={false}
          label="career.jobsFilters.filter1.summary.check2"
        />
        <CustomCheckbox
          isChecked={false}
          label="career.jobsFilters.filter1.summary.check3"
        />
        <CustomCheckbox
          isChecked={false}
          label="career.jobsFilters.filter1.summary.check4"
        />
        <CustomCheckbox
          isChecked={false}
          label="career.jobsFilters.filter1.summary.check5"
        />
        <CustomCheckbox
          isChecked={false}
          label="career.jobsFilters.filter1.summary.check6"
        />
        <CustomCheckbox
          isChecked={false}
          label="career.jobsFilters.filter1.summary.check7"
        />
      </>
    ),
    isDivider: true,
  },
  {
    id: 2,
    summary: "career.jobsFilters.filter2.title",
    details: "",
    isDivider: true,
  },
  {
    id: 3,
    summary: "career.jobsFilters.filter3.title",
    details: "",
    isDivider: true,
  },
  {
    id: 4,
    summary: "career.jobsFilters.filter4.title",
    details: "",
    isDivider: true,
  },
  {
    id: 5,
    summary: "career.jobsFilters.filter5.title",
    details: "",
    isDivider: false,
  },
];

const JobsFilters = () => {
  const { t } = useTranslation();

  return (
    <CustomiseCard padding="24px">
      <TitleWrap>
        <FiltersTitle>{t("career.jobsFilters.title")}</FiltersTitle>
        <ClearFilterLink href="#" underline="none">
          {t("career.jobsFilters.clearFilterLink")}
        </ClearFilterLink>
      </TitleWrap>
      <Divider
        variant="middle"
        sx={{ margin: "16px 0", border: "1px solid #F1F3F5" }}
      />
      <SearchBar placeholder="Search a job" />
      <Divider
        variant="middle"
        sx={{ margin: "16px 0", border: "1px solid #F1F3F5" }}
      />
      <FiltersAccordion accordions={filters} />
    </CustomiseCard>
  );
};

export default JobsFilters;
