import React from "react";
import { MainContainer } from "./style";
import styled from "styled-components";
import NewsSingleMain from "../Components/NewsPage/NewsSingle";
import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import { Breadcrumb } from "../Components/style";
/*import Slider from "../Components/NewsPage/Slider";*/
import { useTranslation } from "react-i18next";

const NewsSingle = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Breadcrumb>
        <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            <img src="/images/home2.svg" alt="" />
            {t("breadCrumb.main")}
          </Link>
          <Link underline="hover" color="inherit" href="/">
            {t("breadCrumb.news")}
          </Link>
          <Link underline="hover" color="inherit" href="/newsSingle" className="active">
            {t("breadCrumb.programs")}
          </Link>
        </Breadcrumbs>
      </Breadcrumb>
      <Container>
        <NewsSingleMain />
        {/*<Slider />*/}
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 5.208333333333333vw;
`;

export default NewsSingle;
