import React from "react";
import styled from "styled-components";
import JobPostDetails from "../Components/JobPostDetails";
import { MainContainer } from "./style";
import { Breadcrumb } from "../Components/style";
import { Breadcrumbs, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const CareerDetails = () => {

  const { t } = useTranslation();
  
  return (
    <MainContainer spacing={true}>
      <Container>
        <Breadcrumb>
          <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              <img src="/images/home2.svg" alt="" />
              {t("breadCrumb.main")}
            </Link>
            <Link underline="hover" color="inherit" href="/">
              {t("breadCrumb.career")}
            </Link>
            <Link underline="hover" color="inherit" href="/career/careerDetails" className="active">
              {t("breadCrumb.salesExecutive")}
            </Link>
          </Breadcrumbs>
        </Breadcrumb>
        <JobPostDetails />
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default CareerDetails;
