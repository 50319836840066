import "./App.css";
import {BrowserRouter as Router, Routes, Route, useParams, useNavigate} from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Banks from "./Pages/UseCase/Banks";
import Emis from "./Pages/UseCase/Emis";
import FamilyOffices from "./Pages/UseCase/FamilyOffices";
import Solutions from "./Pages/Solutions";
import Product from "./Pages/Product";
import Main from "./Pages/Main";
import ContactUsPage from "./Pages/ContactUsPage";
import AboutUS from "./Pages/AboutUS";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Faq from "./Pages/Faq";
import Career from "./Pages/Career";
import ServicePage from "./Pages/ServicePage";
import CareerDetails from "./Pages/CareerDetails";
import News from "./Pages/News";
import NewsSingle from "./Pages/NewsSingle";
import Support from "./Pages/Support";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Pricing from "./Pages/Pricing";
import Modules from "./Pages/Modules";
import {useEffect} from "react";
import ScrollToTop from "./Components/ScrollTop";

function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/useCase/banks" element={<Banks />} />
          <Route path="/useCase/emis" element={<Emis />} />
          <Route path="/useCase/familyOffices" element={<FamilyOffices />} />
          <Route path="/modules" element={<Modules />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/aboutUs" element={<AboutUS />} />
          <Route path="/support" element={<Support />} />
          <Route path="/news" element={<News />} />
          <Route path="/termsAndConditions/:location" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy/:location" element={<PrivacyPolicy />} />
          <Route path="/contactUs" element={<ContactUsPage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/newsSingle" element={<NewsSingle />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/careerDetails" element={<CareerDetails />} />
          <Route path={"/pricing"} element={<Pricing />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
