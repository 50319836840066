const availableJobsList = [
  {
    id: 1,
    jobTitle: "career.jobs.job1Title",
    jobPost: {
      location: "career.jobs.job1Post.location",
      date: "career.jobs.job1Post.date",
      position: "career.jobs.job1Post.position",
    },
    jobDescription: {
      title: "career.jobs.job1Description.title",
      point1: "career.jobs.job1Description.point1",
      point2: "career.jobs.job1Description.point2",
    },
  },
  {
    id: 2,
    jobTitle: "career.jobs.job2Title",
    jobPost: {
      location: "career.jobs.job2Post.location",
      date: "career.jobs.job2Post.date",
      position: "career.jobs.job2Post.position",
    },
    jobDescription: {
      title: "career.jobs.job2Description.title",
      point1: "career.jobs.job2Description.point1",
      point2: "career.jobs.job2Description.point2",
    },
  },
  {
    id: 3,
    jobTitle: "career.jobs.job3Title",
    jobPost: {
      location: "career.jobs.job3Post.location",
      date: "career.jobs.job3Post.date",
      position: "career.jobs.job3Post.position",
    },
    jobDescription: {
      title: "career.jobs.job3Description.title",
      point1: "career.jobs.job3Description.point1",
      point2: "career.jobs.job3Description.point2",
    },
  },
  {
    id: 4,
    jobTitle: "career.jobs.job4Title",
    jobPost: {
      location: "career.jobs.job4Post.location",
      date: "career.jobs.job4Post.date",
      position: "career.jobs.job4Post.position",
    },
    jobDescription: {
      title: "career.jobs.job4Description.title",
      point1: "career.jobs.job4Description.point1",
      point2: "career.jobs.job4Description.point2",
    },
  },
  {
    id: 5,
    jobTitle: "career.jobs.job5Title",
    jobPost: {
      location: "career.jobs.job5Post.location",
      date: "career.jobs.job5Post.date",
      position: "career.jobs.job5Post.position",
    },
    jobDescription: {
      title: "career.jobs.job5Description.title",
      point1: "career.jobs.job5Description.point1",
      point2: "career.jobs.job5Description.point2",
    },
  },
  {
    id: 6,
    jobTitle: "career.jobs.job6Title",
    jobPost: {
      location: "career.jobs.job6Post.location",
      date: "career.jobs.job6Post.date",
      position: "career.jobs.job6Post.position",
    },
    jobDescription: {
      title: "career.jobs.job6Description.title",
      point1: "career.jobs.job6Description.point1",
      point2: "career.jobs.job6Description.point2",
    },
  },
  {
    id: 7,
    jobTitle: "career.jobs.job7Title",
    jobPost: {
      location: "career.jobs.job7Post.location",
      date: "career.jobs.job7Post.date",
      position: "career.jobs.job7Post.position",
    },
    jobDescription: {
      title: "career.jobs.job7Description.title",
      point1: "career.jobs.job7Description.point1",
      point2: "career.jobs.job7Description.point2",
    },
  },
  {
    id: 8,
    jobTitle: "career.jobs.job8Title",
    jobPost: {
      location: "career.jobs.job8Post.location",
      date: "career.jobs.job8Post.date",
      position: "career.jobs.job8Post.position",
    },
    jobDescription: {
      title: "career.jobs.job8Description.title",
      point1: "career.jobs.job8Description.point1",
      point2: "career.jobs.job8Description.point2",
    },
  },
];

const jobs = {
  availableJobsList,
};

export default jobs;
