import React from "react";
import styled from "styled-components";
import SearchBar from "../Components/Searchbar";
import VerticalTabs from "../Components/VerticalTabs";
import { MainContainer } from "./style";
import { useTranslation } from "react-i18next";
import { ImageContent, ImageDiv } from "../Components/Support/style";
import { Breadcrumb } from "../Components/style";
import { Breadcrumbs, Link } from "@mui/material";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <MainContainer spacing={true}>
      <Breadcrumb>
        <Breadcrumbs separator={<img src="/images/breadcrumb-icon.svg" alt="not-found" />} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            <img src="/images/home2.svg" alt="" />
            {t("breadCrumb.main")}
          </Link>
          <Link underline="hover" color="inherit" href="#!">
            {t("breadCrumb.resources")}
          </Link>
          <Link underline="hover" color="inherit" href="/faq" className="active">
            {t("breadCrumb.faq")}
          </Link>
        </Breadcrumbs>
      </Breadcrumb>
      <Container>
        <ImageDiv>
          <ImageContent>
            <p className="title">{t("faq.question")}</p>
            <SearchBar placeholder="Search" />
          </ImageContent>
        </ImageDiv>
        <VerticalTabs />
      </Container>
    </MainContainer>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 5.208333333333333vw;
`;

export default Faq;
