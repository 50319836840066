import { Heading, Para, ParaTitle } from "../style";
import { useTranslation } from "react-i18next";
import { FlexDiv, HeadingSecondary, NewsContainer, NewsContent, MainImg, FlexButtons } from "./style";

const NewsSingleMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <NewsContainer>
        <MainImg src="images/news-single.svg" alt="news-single" />
        <NewsContent>
          <FlexDiv justifyContent="space-between">
            <FlexDiv>
              <img src="/images/business-cloud.svg" alt="#" />
              <div>
                <Para>{t("news.publishedBy")}</Para>
                <HeadingSecondary>{t("news.businessCloud")}</HeadingSecondary>
              </div>
            </FlexDiv>
            <Para>{t("newsSingle.jan")}</Para>
          </FlexDiv>
          <Heading>{t("newsSingle.heading1")}</Heading>
          <Para>{t("newsSingle.para1")}</Para>
          <Para>{t("newsSingle.para2")}</Para>
          <Para>{t("newsSingle.para3")}</Para>
          <Para>{t("newsSingle.para4")}</Para>
          <Para>“{t("newsSingle.para5")}</Para>
          <Para>“{t("newsSingle.para6")}”</Para>
          <Para>{t("newsSingle.para7")}</Para>
          <Para>{t("newsSingle.para8")}</Para>
          <Para>{t("newsSingle.para9")}</Para>
          <Para>{t("newsSingle.para10")}</Para>
          <Para>{t("newsSingle.para11")}</Para>
          <Para>{t("newsSingle.para12")}</Para>
          <MainImg src="/images/image 31.svg" alt="#" />
          <Para>{t("newsSingle.para13")}</Para>
          <Para>{t("newsSingle.para14")}</Para>
          <Para>{t("newsSingle.para15")}</Para>
          <ParaTitle>{t("newsSingle.share")}</ParaTitle>
          <FlexButtons>
            <button>
              <img src="images/copy.svg" alt="" />
              <Para>{t("newsSingle.copy")}</Para>
            </button>
            <img src="images/linkedin1.svg" alt="" />
            <img src="images/facebook.svg" alt="" />
            <img src="images/youtube.svg" alt="" />
            <img src="images/telegram.svg" alt="" />
          </FlexButtons>
        </NewsContent>
      </NewsContainer>
    </>
  );
};
export default NewsSingleMain;
