import styled from "styled-components";
import { Card, CardActions } from "@mui/material";

export const NewsWrapper = styled.div`
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-4.css-11l5t4l-MuiGrid-root {
    display: grid;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin: 0;
  justify-content: ${(props) =>
    props.justifyContent === "space-between" ? "space-between" : "unset"};
`;

export const FlexButtons = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  button {
    border: none;
    display: flex;
    border-radius: 16px;
    align-items: center;
    background: #fff;
    gap: 12px;
    padding: 14px 12px;
    p {
      color: #ff931e;
    }
  }

  img {
    background: #fff;
    border-radius: 16px;
  }
`;
export const HeadingSecondary = styled.p`
  font-family: "SF Pro Text";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #121212;
  margin: 0;
`;
export const NewsContainer = styled.div``;
export const CenterHeading = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  text-align: center;
  color: #121212;
  margin: 0;
  padding: 15px 0 36px 0;
  @media screen and (max-width: 600px) {
    padding: 31px 0;
    text-align: left;
    font-size: 24px;
  }
`;

export const ImageSlider = styled.div`
  padding: 16px 0 0 0px;
  @media screen and (max-width: 800px) {
    padding: 20px 16px 0 16px;
  }
  .owl-nav {
    display: flex !important;
    gap: 10px;
    position: absolute;
    top: -45px;
    right: 105px;
    .owl-prev > img,
    .owl-next > img {
      font-weight: 400;
      width: 36px;
    }
    @media screen and (max-width: 900px) {
      top: -27px;
      right: 16px;
      .owl-prev > img,
      .owl-next > img {
        width: 25px !important;
      }
    }
    @media screen and (max-width: 567px) {
      .owl-prev > img,
      .owl-next > img {
        width: 18px !important;
      }
    }
  }
  .owl-carousel {
    padding: 34px 0 216px 0;
    @media screen and (max-width: 567px) {
      padding: 12px 0 36px 0;
    }
  }
`;

export const Para = styled.p`
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
`;

export const NewsContent = styled.div`
  padding: 32px 211px 70px 211px;
  display: grid;
  gap: 12px;
  @media screen and (max-width: 1000px) {
    padding: 16px 0 0 0;
  }
`;

export const NewsCard = styled(Card)`
  background: #ffffff;
  border-radius: 16px !important;
  box-shadow: none !important;
  padding-left: 0;
`;

export const MainImg = styled.img`
  width: 100%;
  border-radius: 16px;
  @media screen and (max-width: 1200px) {
    height: auto;
  }
`;

export const CustomCardActions = styled(CardActions)``;

export const PaginationWrapper = styled.div`
  padding: 42px 0 190px 0;
  @media screen and (max-width: 600px) {
    padding: 16px 0 264px 0;
  }
`;

export const CardBtn = styled.button`
  padding: 8px 16px;
  width: 64px;
  height: 35px;
  background: rgba(162, 162, 163, 0.08);
  border-radius: 36px;
  border: none;
  cursor: pointer;
`;
