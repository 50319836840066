import React from "react";
import { CustomDropDown, CustomPagination, DropDownLabel, JobsListFilterWrapper, PageSelectWrap, Wrap, Wrapper } from "./style";
import { Heading } from "../style";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, PaginationItem } from "@mui/material";
import JobsList from "../JobsList";
import jobs from "../../Constants/jobs";
import JobsFilters from "../JobsFilters";
import { SelectIcon } from "../../Icons/SelectIcon";
import { FirstPageIcon } from "../../Icons/FirstPageIcon";
import { LastPageIcon } from "../../Icons/LastPageIcon";

const JobOpportunity = () => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = React.useState(1);
  const [showFrom, setShowFrom] = React.useState(1);

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleShowFromChange = (event) => {
    setShowFrom(event.target.value);
  };

  return (
    <Wrapper>
      <Wrap>
        <Heading>{t("career.jobOpportunity.title")}</Heading>
        <div>
          <DropDownLabel>{t("career.jobOpportunity.filter.title")}</DropDownLabel>
          <CustomDropDown value={sortBy} onChange={handleChange} IconComponent={SelectIcon}>
            <MenuItem value={1}>{t("career.jobOpportunity.filter.option1")}</MenuItem>
            <MenuItem value={2}>{t("career.jobOpportunity.filter.option2")}</MenuItem>
            <MenuItem value={3}>{t("career.jobOpportunity.filter.option3")}</MenuItem>
          </CustomDropDown>
        </div>
      </Wrap>
      <Grid container columns={{ xs: 1, sm: 6, md: 12 }} spacing={2}>
        <Grid item xs={1} sm={6} md={4}>
          <div>
            <JobsFilters />
          </div>
        </Grid>
        <Grid item xs={1} sm={6} md={8}>
          <div>
            <JobsList jobs={jobs.availableJobsList} />
            <JobsListFilterWrapper>
              <PageSelectWrap>
                <DropDownLabel>{t("career.jobs.filter.title")}</DropDownLabel>
                <CustomDropDown value={showFrom} onChange={handleShowFromChange} IconComponent={SelectIcon}>
                  <MenuItem value={1}>{t("career.jobs.filter.option1")}</MenuItem>
                  <MenuItem value={2}>{t("career.jobs.filter.option2")}</MenuItem>
                  <MenuItem value={3}>{t("career.jobs.filter.option3")}</MenuItem>
                </CustomDropDown>
                <DropDownLabel>{t("career.jobs.filter.title2")}</DropDownLabel>
              </PageSelectWrap>
              <CustomPagination
                count={10}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      first: FirstPageIcon,
                      last: LastPageIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </JobsListFilterWrapper>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default JobOpportunity;
