import { Link } from "@mui/material";
import styled from "styled-components";

export const FiltersTitle = styled.p`
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #121212;

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearFilterLink = styled(Link)`
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ff931e !important; ;
`;
