import React from "react";
import { JobPostFilterWrap, JobPostLink, JobPostTab, JobPostTabLabelWrap, JobPostTabs, Wrapper } from "./style";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import JobPostDetail from "../JobPostDetail";
import TabPanel from "../TabPanel";
import { CustomiseCard } from "../style";
import { CustomDropDown, DropDownLabel } from "../JobOpportunity/style";
import { SelectIcon } from "../../Icons/SelectIcon";
import { JobPostWrap, JobTitle } from "../JobsList/style";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const JobPostDetails = () => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [sortBy, setSortBy] = React.useState(1);

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <Wrapper>
      <Grid container columns={{ xs: 1, sm: 6, md: 12 }} spacing={2}>
        <Grid item xs={1} sm={6} md={4}>
          <div>
            <CustomiseCard padding="0 0 24px 0">
              <JobPostFilterWrap>
                <JobPostLink href="#" underline="none">
                  <img src="/images/LeftArrow.svg" alt="not-found" />
                  {t("careerDetails.jobPostDetails.filter.title")}
                </JobPostLink>
                <div>
                  <DropDownLabel>{t("careerDetails.jobPostDetails.filter.sorting.title")}</DropDownLabel>
                  <CustomDropDown value={sortBy} onChange={handleSortByChange} IconComponent={SelectIcon}>
                    <MenuItem value={1}>{t("careerDetails.jobPostDetails.filter.sorting.option1")}</MenuItem>
                    <MenuItem value={2}>{t("careerDetails.jobPostDetails.filter.sorting.option2")}</MenuItem>
                    <MenuItem value={3}>{t("careerDetails.jobPostDetails.filter.sorting.option3")}</MenuItem>
                  </CustomDropDown>
                </div>
              </JobPostFilterWrap>
              <JobPostTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Faq Tabs"
                TabIndicatorProps={{
                  style: {
                    background: "#FF931E",
                    borderRadius: "0px 4px 4px 0px",
                    width: "4px",
                    left: "0",
                  },
                }}
              >
                <JobPostTab
                  label={
                    <>
                      <JobPostTabLabelWrap>
                        <JobTitle>{t("careerDetails.jobPostDetails.filter.tabs.tab1.title")}</JobTitle>
                        <JobPostWrap style={{ margin: "8px 0" }}>
                          <img src="/images/LocationLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab1.location")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/TimeLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab1.date")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/ContentLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab1.post")}</span>
                        </JobPostWrap>
                      </JobPostTabLabelWrap>
                    </>
                  }
                  {...a11yProps(0)}
                />
                <JobPostTab
                  label={
                    <>
                      <JobPostTabLabelWrap>
                        <JobTitle>{t("careerDetails.jobPostDetails.filter.tabs.tab2.title")}</JobTitle>
                        <JobPostWrap style={{ margin: "8px 0" }}>
                          <img src="/images/LocationLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab2.location")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/TimeLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab2.date")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/ContentLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab2.post")}</span>
                        </JobPostWrap>
                      </JobPostTabLabelWrap>
                    </>
                  }
                  {...a11yProps(1)}
                />
                <JobPostTab
                  label={
                    <>
                      <JobPostTabLabelWrap>
                        <JobTitle>{t("careerDetails.jobPostDetails.filter.tabs.tab3.title")}</JobTitle>
                        <JobPostWrap style={{ margin: "8px 0" }}>
                          <img src="/images/LocationLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab3.location")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/TimeLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab3.date")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/ContentLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab3.post")}</span>
                        </JobPostWrap>
                      </JobPostTabLabelWrap>
                    </>
                  }
                  {...a11yProps(2)}
                />
                <JobPostTab
                  label={
                    <>
                      <JobPostTabLabelWrap>
                        <JobTitle>{t("careerDetails.jobPostDetails.filter.tabs.tab4.title")}</JobTitle>
                        <JobPostWrap style={{ margin: "8px 0" }}>
                          <img src="/images/LocationLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab4.location")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/TimeLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab4.date")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/ContentLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab4.post")}</span>
                        </JobPostWrap>
                      </JobPostTabLabelWrap>
                    </>
                  }
                  {...a11yProps(2)}
                />
                <JobPostTab
                  label={
                    <>
                      <JobPostTabLabelWrap>
                        <JobTitle>{t("careerDetails.jobPostDetails.filter.tabs.tab5.title")}</JobTitle>
                        <JobPostWrap style={{ margin: "8px 0" }}>
                          <img src="/images/LocationLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab5.location")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/TimeLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab5.date")}</span>
                        </JobPostWrap>
                        <JobPostWrap style={{ marginBottom: "8px" }}>
                          <img src="/images/ContentLiner.svg" alt="not-found" />
                          <span>{t("careerDetails.jobPostDetails.filter.tabs.tab5.post")}</span>
                        </JobPostWrap>
                      </JobPostTabLabelWrap>
                    </>
                  }
                  {...a11yProps(2)}
                />
              </JobPostTabs>
            </CustomiseCard>
          </div>
        </Grid>
        <Grid item xs={1} sm={6} md={8}>
          <div>
            <TabPanel value={value} index={0}>
              <JobPostDetail />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default JobPostDetails;
