const aboutUsFaqs = [
  {
    id: 1,
    summary: "faq.que1",
    details: "faq.ans1",
    isDivider: false,
  },
  {
    id: 2,
    summary: "faq.que2",
    details: "faq.ans2",
    isDivider: false,
  },
  {
    id: 3,
    summary: "faq.que3",
    details: "faq.ans3",
    isDivider: false,
  },
  {
    id: 4,
    summary: "faq.que4",
    details: "faq.ans4",
    isDivider: false,
  },
  {
    id: 5,
    summary: "faq.que5",
    details: "faq.ans5",
    isDivider: false,
  },
  {
    id: 6,
    summary: "faq.que6",
    details: "faq.ans6",
    isDivider: false,
  },
  {
    id: 7,
    summary: "faq.que7",
    details: "faq.ans7",
    isDivider: false,
  },
  {
    id: 8,
    summary: "faq.que8",
    details: "faq.ans8",
    isDivider: false,
  },
  {
    id: 9,
    summary: "faq.que9",
    details: "faq.ans9",
    isDivider: true,
  },
  {
    id: 10,
    summary: "faq.que10",
    details: "faq.ans10",
    isDivider: false,
  },
  {
    id: 11,
    summary: "faq.que11",
    details: "faq.ans11",
    isDivider: false,
  },
  {
    id: 12,
    summary: "faq.que12",
    details: "faq.ans12",
    isDivider: false,
  },
  {
    id: 13,
    summary: "faq.que13",
    details: "faq.ans13",
    isDivider: false,
  },
  {
    id: 14,
    summary: "faq.que14",
    details: "faq.ans14",
    isDivider: false,
  },
  {
    id: 15,
    summary: "faq.que15",
    details: "faq.ans15",
    isDivider: false,
  },
  {
    id: 16,
    summary: "faq.que16",
    details: "faq.ans16",
    isDivider: false,
  },
  {
    id: 17,
    summary: "faq.que17",
    details: "faq.ans17",
    isDivider: false,
  },
  {
    id: 18,
    summary: "faq.que18",
    details: "faq.ans18",
    isDivider: false,
  },
  {
    id: 19,
    summary: "faq.que19",
    details: "faq.ans19",
    isDivider: false,
  },
  {
    id: 20,
    summary: "faq.que20",
    details: "faq.ans20",
    isDivider: false,
  },
  {
    id: 21,
    summary: "faq.que21",
    details: "faq.ans21",
    isDivider: false,
  },
  {
    id: 22,
    summary: "faq.que22",
    details: "faq.ans22",
    isDivider: false,
  },
  {
    id: 23,
    summary: "faq.que23",
    details: "faq.ans23",
    isDivider: false,
  },
  {
    id: 24,
    summary: "faq.que24",
    details: "faq.ans24",
    isDivider: true,
  },
  {
    id: 25,
    summary: "faq.que25",
    details: "faq.ans25",
    isDivider: false,
  },
  {
    id: 26,
    summary: "faq.que26",
    details: "faq.ans26",
    isDivider: false,
  },
  {
    id: 27,
    summary: "faq.que27",
    details: "faq.ans27",
    isDivider: false,
  },
  {
    id: 28,
    summary: "faq.que28",
    details: "faq.ans28",
    isDivider: false,
  },
  {
    id: 29,
    summary: "faq.que29",
    details: "faq.ans29",
    isDivider: false,
  },
  {
    id: 30,
    summary: "faq.que30",
    details: "faq.ans30",
    isDivider: false,
  },
  {
    id: 31,
    summary: "faq.que31",
    details: "faq.ans31",
    isDivider: false,
  },
  {
    id: 32,
    summary: "faq.que32",
    details: "faq.ans32",
    isDivider: false,
  },
  {
    id: 33,
    summary: "faq.que33",
    details: "faq.ans33",
    isDivider: false,
  },
  {
    id: 34,
    summary: "faq.que34",
    details: "faq.ans34",
    isDivider: false,
  },
  {
    id: 35,
    summary: "faq.que35",
    details: "faq.ans35",
    isDivider: false,
  },
  {
    id: 36,
    summary: "faq.que36",
    details: "faq.ans36",
    isDivider: false,
  },
  {
    id: 37,
    summary: "faq.que37",
    details: "faq.ans37",
    isDivider: false,
  },
  {
    id: 38,
    summary: "faq.que38",
    details: "faq.ans38",
    isDivider: false,
  },
  {
    id: 39,
    summary: "faq.que39",
    details: "faq.ans39",
    isDivider: false,
  },
  {
    id: 40,
    summary: "faq.que40",
    details: "faq.ans40",
    isDivider: false,
  },
  {
    id: 41,
    summary: "faq.que41",
    details: "faq.ans41",
    isDivider: false,
  },
  {
    id: 42,
    summary: "faq.que42",
    details: "faq.ans42",
    isDivider: false,
  },
  {
    id: 43,
    summary: "faq.que43",
    details: "faq.ans43",
    isDivider: false,
  },
  {
    id: 44,
    summary: "faq.que44",
    details: "faq.ans44",
    isDivider: false,
  },
  {
    id: 45,
    summary: "faq.que45",
    details: "faq.ans45",
    isDivider: false,
  },
  {
    id: 46,
    summary: "faq.que46",
    details: "faq.ans46",
    isDivider: false,
  },
  {
    id: 47,
    summary: "faq.que47",
    details: "faq.ans47",
    isDivider: false,
  },
];

const marketAndBusinessFaqs = [
  {
    id: 1,
    summary: "faq.que11",
    details: "faq.ans11",
    isDivider: false,
  },
  {
    id: 2,
    summary: "faq.que22",
    details: "faq.ans22",
    isDivider: false,
  },
  {
    id: 3,
    summary: "faq.que33",
    details: "faq.ans33",
    isDivider: false,
  },
  {
    id: 4,
    summary: "faq.que44",
    details: "faq.ans44",
    isDivider: false,
  },
  {
    id: 5,
    summary: "faq.que35",
    details: "faq.ans35",
    isDivider: true,
  },
  {
    id: 6,
    summary: "faq.que36",
    details: "faq.ans36",
    isDivider: false,
  },
  {
    id: 7,
    summary: "faq.que17",
    details: "faq.ans17",
    isDivider: false,
  },
  {
    id: 8,
    summary: "faq.que28",
    details: "faq.ans28",
    isDivider: false,
  },
  {
    id: 9,
    summary: "faq.que29",
    details: "faq.ans29",
    isDivider: false,
  },
  {
    id: 10,
    summary: "faq.que3",
    details: "faq.ans3",
    isDivider: false,
  },
];

const productAndServicesFaqs = [
  {
    id: 1,
    summary: "faq.que10",
    details: "faq.ans10",
    isDivider: false,
  },
  {
    id: 2,
    summary: "faq.que21",
    details: "faq.ans21",
    isDivider: false,
  },
  {
    id: 3,
    summary: "faq.que31",
    details: "faq.ans31",
    isDivider: true,
  },
  {
    id: 4,
    summary: "faq.que41",
    details: "faq.ans41",
    isDivider: false,
  },
  {
    id: 5,
    summary: "faq.que25",
    details: "faq.ans25",
    isDivider: false,
  },
  {
    id: 6,
    summary: "faq.que16",
    details: "faq.ans16",
    isDivider: false,
  },
  {
    id: 7,
    summary: "faq.que37",
    details: "faq.ans37",
    isDivider: false,
  },
  {
    id: 8,
    summary: "faq.que28",
    details: "faq.ans28",
    isDivider: true,
  },
  {
    id: 9,
    summary: "faq.que9",
    details: "faq.ans9",
    isDivider: false,
  },
  {
    id: 10,
    summary: "faq.que10",
    details: "faq.ans10",
    isDivider: false,
  },
  {
    id: 11,
    summary: "faq.que11",
    details: "faq.ans11",
    isDivider: false,
  },
  {
    id: 12,
    summary: "faq.que12",
    details: "faq.ans12",
    isDivider: false,
  },
];

const faqs = {
  aboutUsFaqs,
  marketAndBusinessFaqs,
  productAndServicesFaqs,
};

export default faqs;
