import styled from "styled-components";

export const FrameTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.2px;
  color: #121212;
  text-align: ${(props) => props.textAlign ?? "start"};
  span {
    color: #ff931e;
  }

  @media screen and (max-width: 899px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const FrameContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  max-width: 590px;
  margin-bottom: 72px;
  .theses {
    font-size: 20px;
    padding-left: 20px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 899px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
export const FrameContentTitle = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ff931e;

  @media screen and (max-width: 899px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const FrameCard = styled.div`
  background: #ffffff;
  border-radius: 24px;
  margin-bottom: 16px;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #121212;
  .content {
    margin-left: 16px;
    width: 100%;
    .mailLink {
      color: rgb(255, 147, 30);
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-decoration: none;
     
      span {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  span {
    font-weight: 700;
  }

  @media screen and (max-width: 899px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
export const Title = styled.p`
  margin: 0 0 8px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4d4d4d;
`;

export const SubTitle = styled.p`
  margin: 0 0 16px 0;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #121212;
`;

export const Heading = styled.p`
  margin: ${(props) => props.margin ?? "0"};
  padding: ${(props) => props.padding ?? "0"};
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.2px;
  color: #121212;

  span {
    color: #ff931e;
  }

  @media screen and (max-width: 899px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const ParaTitle = styled.p`
  margin: ${(props) => props.margin ?? "0"};
  padding: ${(props) => props.padding ?? "0"};
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #121212;

  span {
    color: #ff931e;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Para = styled.p`
  margin: ${(props) => props.margin ?? "0"};
  padding: ${(props) => props.padding ?? "0"};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;

  span {
    color: #ff931e;
  }

  @media screen and (max-width: 899px) {
    font-size: 14px;
    line-height: 20px;
  }
  .mailLink{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const CustomiseCard = styled.div`
  background: ${(props) => props.background ?? "#ffffff"};
  border-radius: 24px;
  height: 100%;
  padding: ${(props) => props.padding ?? "unset"};
  margin: ${(props) => props.margin ?? "unset"};

  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.padding ? "18px" : "unset")};
    margin: ${(props) => (props.margin ? "8px 0" : "unset")};
  }
`;

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.2px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: 64px 0px 24px 0px;
`;

export const Container = styled.div`
  padding: 0 5.208333333333333vw 72px 5.208333333333333vw;
`;

export const BtnOrange = styled.button`
  background: #ff931e;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 14px 24px;
`;

export const CardHeading = styled.p`
  margin: 0;
`;
export const CardPara = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: "SF Pro Text";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.active === "true" ? "#686C72 !important " : "#8f9aa8 !important")};
`;

export const CardContent = styled.div`
  display: grid;
  gap: 12px;
`;

export const Date = styled(CardPara)`
  text-transform: uppercase;
`;

export const Breadcrumb = styled.div`
  font-family: "SF Pro Text" !important;
  padding: 18px 5.208333333333333vw;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #727171;
  }
  .active {
    color: #4d4d4d;
  }
`;
